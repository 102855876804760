<template>
  <!-- eslint-disable vue/html-indent -->
  <!-- eslint-disable vue/max-attributes-per-line -->
  <b-row>
    <b-col sm="9">
      <Fieldset id="productDetail" :title="$t('Product Detail')">
        <b-row>
          <b-col sm="7">
            <div class="d-flex box mb-2">
              <div class="img-important mr-2">
                <b-img :src="model.avatar" />
              </div>
              <div>
                <p>{{ model.name }}</p>
                <p>{{ model.sku }}</p>
                <!-- <div
                  v-for="(item, index) in model.short_content"
                  :key="index"
                  class="mt-2"
                >
                  <strong v-if="item.type === 'content'">
                    {{ item.value }}
                  </strong>
                  <div v-if="item.type === 'table'">
                    <div v-for="(i, idx) in item.value" :key="idx">
                      <strong>{{ i.title }}</strong> - {{ i.content }}
                    </div>
                  </div>
                </div> -->
                <!-- <strong>{{
                     && model.short_content !== null
                      ? model.short_content
                      : model.content
                  }}</strong> -->
              </div>
            </div>
          </b-col>
          <b-col sm="5">
            <p><strong>{{ $t('Category') }}: </strong>{{ model.category_name }}</p>
            <p><strong>{{ $t('Supplier') }}: </strong>{{ model.supplier_name }}</p>
          </b-col>
        </b-row>
        <b-table
          :items="items"
          :fields="fields"
          striped
          responsive
          :per-page="perPage"
          :current-page="currentPage"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          :sort-direction="sortDirection"
        >
          <template #cell(avatar)="item">
            <b-avatar :src="item.item.avatar" />
          </template>
          <template #cell(variation)="item">
            <span>
              {{ item.item.name_att1 }}
            </span>
            <span v-if="item.item.name_att2 !== null">
              - {{ item.item.name_att2 }}
            </span>
            <span v-if="item.item.name_att3 !== null">
              - {{ item.item.name_att3 }}
            </span>
          </template>
          <template #cell(resold)="item">
            <div class="d-flex">
              <span>{{ item.item.resold }}</span>
              <div
                v-if="item.item.resold !== 0"
                v-b-modal.modal-xl-resold
                class="text-secondary cursor-pointer ml-2"
                @click="getDataResold(item.item.id)"
              >
                <feather-icon icon="LinkIcon" />
              </div>
            </div>
          </template>

          <template #cell(is_active)="item">
            <ActiveStatus :value="item.item.is_active" />
          </template>
        </b-table>
        <popup-resold :data="resoldList" @handleLoadWarehouse="loadList" />
        <b-row>
          <b-col md="2" sm="4" class="my-1">
            <b-form-group class="mb-0">
              <label class="d-inline-block text-sm-left mr-50">
                {{ $t('Per page') }}
              </label>
              <b-form-select
                id="perPageSelect"
                v-model="perPage"
                size="sm"
                :options="pageOptions"
                class="w-50"
              />
            </b-form-group>
          </b-col>
          <b-col md="10" sm="8" class="my-1">
            <b-pagination
              v-model="currentPage"
              :total-rows="totalRows"
              :per-page="perPage"
              align="center"
              class="my-0"
            />
          </b-col>
        </b-row>
      </Fieldset>
    </b-col>
    <b-col sm="3">
      <div class="position-sticky top">
        <Fieldset id="export" title="Export">
          <div class="d-flex mb-2">
            <b-button
              v-b-modal.modal-xl-export
              class="mr-1 w-100"
              variant="outline-secondary"
            >
              {{ $t('Export goods') }}
            </b-button>
            <popup-export :data="model" :list="items" @handleLoadWarehouse="loadList" />
            <b-button v-b-modal.modal-xl class="w-100" variant="primary">
              {{ $t('Receive goods') }}
            </b-button>
            <popup-receive :data="model" :list="items" @handleLoadWarehouse="loadList" />
          </div>
        </Fieldset>

        <Fieldset id="history" :title="$t('History')">
          <h3>{{ $t('History') }}</h3>
          <div
            v-for="(item, index) in historyShow"
            :key="index"
            class="d-flex space-between"
          >
            <div>
              <div class="d-flex">
                <strong>{{ item.title }}</strong>
                <i class="mb-0 ml-2">
                  <small>{{ item.created_at }}</small>
                </i>
              </div>
              <p class="text-primary">
                {{ item.id }}
              </p>
            </div>
            <b-button
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant="outline-primary"
              class="btn-icon"
              size="sm"
            >
              <feather-icon icon="FileTextIcon" />
            </b-button>
          </div>
          <div>
            <Pagination
              v-model="history"
              :per-page="perPage"
              :current-page="currentPageHistory"
              @items-per-page="PaginationPerPage"
              @pagechanged="onPageChange"
            />
          </div>
          <div v-if="!history || history.length == []">
            {{ $t('No History for this product') }}
          </div>
        </Fieldset>
      </div>
    </b-col>
  </b-row>
</template>

<script>
/* eslint-disable array-callback-return */
import {
  BRow,
  BCol,
  BImg,
  BButton,
  BTable,
  BAvatar,
  BFormGroup,
  BFormSelect,
  BPagination,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import general from '@/mixins/index'
import PopupReceive from '@/views/pages/warehouse/components/PopupReceive.vue'
import PopupExport from '@/views/pages/warehouse/components/PopupExport.vue'
import PopupResold from '@/views/pages/warehouse/components/PopupResold.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Request from '@/plugins/service/index'

export default {
  components: {
    BRow,
    BCol,
    BImg,
    BButton,
    BTable,
    PopupReceive,
    PopupExport,
    PopupResold,
    BAvatar,
    BFormGroup,
    BFormSelect,
    BPagination,
  },
  directives: {
    Ripple,
  },
  mixins: [general],
  data() {
    return {
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      pageOptions: [3, 5, 10],
      totalRows: 1,
      currentPage: 1,
      currentPageHistory: 1,
      perPage: 5,
      limit: 9,
      fields: [
        { key: 'avatar', label: 'Avatar' },
        { key: 'sku', label: 'SKU' },
        { key: 'variation', label: 'Variation' },
        { key: 'price', label: 'Selling price', sortable: true },
        { key: 'stock', label: 'stock', sortable: true },
        { key: 'resold', label: 'Temporarily holding stock', sortable: true },
        { key: 'sold', label: 'Sold', sortable: true },
        { key: 'is_active', label: 'Status' },
        { key: 'updated_at', label: 'Lasted update' },
      ],
      model: {},
      items: [],
      history: [],
      resoldList: {},
      historyShow: [],
    }
  },
  mounted() {
    // this.loadList()
    this.loadDetail()
    this.loadHistory()
  },
  methods: {
    async loadList() {
      const siteId = JSON.parse(localStorage.getItem('siteID')).id
      try {
        const res = await Request.get(
          this.$http,
          `${process.env.VUE_APP_API_URL}/warehouses?get_order_item=1&site_id=${siteId}&product_id=${this.$route.query.product_id}`,
        )
        if (res.status === 200) {
          if (res.data.status) {
            // this.items = res.data.data.items
            let temp = {}
            this.items = []
            res.data.data.items.map(val => {
              temp = {
                avatar: val.avatar,
                sku: val.sku,
                name_att1: val.name_att1,
                name_att2: val.name_att2,
                name_att3: val.name_att3,
                price: val.price + this.model.currentPrice,
                stock: val.stock,
                sold: val.sold,
                is_active: val.is_active,
                updated_at: val.updated_at,
                id: val.id,
                list_order_items: val.list_order_items,
                resold: val.list_order_items.length > 0 ? val.list_order_items.map(item => item.quantity).reduce((prev, next) => prev + next) : 0,
              }
              this.items.push(temp)
            })
            this.currentPage = res.data.data.current_page
            this.totalRows = res.data.data.items.length
          } else {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'Opps! Something wrong',
                icon: 'AlertOctagonIcon',
                variant: 'danger',
                text: String(this.showError(res.data.error, ',')),
              },
            })
          }
        }
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Opps! Something wrong',
            icon: 'AlertOctagonIcon',
            variant: 'danger',
            text: String(error),
          },
        })
      }
    },
    async loadDetail() {
      const siteId = JSON.parse(localStorage.getItem('siteID')).id
      try {
        const res = await Request.get(
          this.$http,
          `${process.env.VUE_APP_API_URL}/product/${this.$route.query.product_id}?site_id=${siteId}`,
        )
        if (res.status === 200) {
          if (res.data.status) {
            this.model = await {
              ...res.data.data,
              currentPrice: res.data.data.price,
              supplier_name: null,
              category_name: '',
              code: this.generateCode('R', 1, 5),
              status: 1,
              unit_price: null,
              total_price: null,
              reciept_date: '2021/04/12 12:03:05',
              short_content:
                res.data.data.short_content !== ''
                  ? JSON.parse(res.data.data.short_content)
                  : '',
            }
            const resSupplier = await Request.get(
              this.$http,
              `${process.env.VUE_APP_API_URL}/supplier/${res.data.data.supplier_id}?site_id=${siteId}`,
            )
            if (resSupplier.status === 200) {
              if (resSupplier.data.status) {
                this.model.supplier_name = resSupplier.data.data.name
              } else {
                this.model.supplier_name = 'n/a'
                this.$toast({
                  component: ToastificationContent,
                  position: 'top-right',
                  props: {
                    title: 'Opps! Something wrong',
                    icon: 'AlertOctagonIcon',
                    variant: 'danger',
                    text: String(this.showError(resSupplier.data.error, ',')),
                  },
                })
              }
            }
            this.getCategoryProduct(res.data.data.category_id)
            this.loadList()
          } else {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'Opps! Something wrong',
                icon: 'AlertOctagonIcon',
                variant: 'danger',
                text: String(this.showError(res.data.error, ',')),
              },
            })
          }
        }
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Opps! Something wrong',
            icon: 'AlertOctagonIcon',
            variant: 'danger',
            text: String(error),
          },
        })
      }
    },
    async loadHistory() {
      const siteId = JSON.parse(localStorage.getItem('siteID')).id
      try {
        const res = await Request.get(
          this.$http,
          `${process.env.VUE_APP_API_URL}/history_warehouse/show?site_id=${siteId}&warehouse_id=${this.$route.params.id}`,
        )
        if (res.status === 200) {
          if (res.data.status) {
            // import/export : 0/1
            res.data.data.items.map(val => {
              const item = {
                id: val.sku,
                title: val.type === 0 ? 'Receive goods' : 'Export goods',
                created_at: val.created_at,
              }
              this.history.push(item)
            })
          } else {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'Opps! Something wrong',
                icon: 'AlertOctagonIcon',
                variant: 'danger',
                text: String(this.showError(res.data.error, ',')),
              },
            })
          }
        }
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Opps! Something wrong',
            icon: 'AlertOctagonIcon',
            variant: 'danger',
            text: String(error),
          },
        })
      }
    },
    async getCategoryProduct(categoryID) {
      const siteId = JSON.parse(localStorage.getItem('siteID')).id
      const categoryIDSplit = categoryID.split(',')
      const resCategory1 = await Request.get(
        this.$http,
        `${process.env.VUE_APP_API_URL}/category/${categoryIDSplit[0]}?site_id=${siteId}`,
      )
      if (resCategory1.status === 200) {
        if (resCategory1.data.status) {
          this.model.category_name = `${resCategory1.data.data.name}`
        } else {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Opps! Something wrong',
              icon: 'AlertOctagonIcon',
              variant: 'danger',
              text: String(this.showError(resCategory1.data.error, ',')),
            },
          })
        }
      }
      if (categoryIDSplit[1] && categoryIDSplit[1] !== undefined) {
        const resCategory2 = await Request.get(
          this.$http,
          `${process.env.VUE_APP_API_URL}/category/${categoryIDSplit[1]}?site_id=${siteId}`,
        )
        if (resCategory2.status === 200) {
          if (resCategory2.data.status) {
            this.model.category_name += ` > ${resCategory2.data.data.name}`
          } else {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'Opps! Something wrong',
                icon: 'AlertOctagonIcon',
                variant: 'danger',
                text: String(this.showError(resCategory2.data.error, ',')),
              },
            })
          }
        }
      }
      if (categoryIDSplit[2] && categoryIDSplit[2] !== undefined) {
        const resCategory3 = await Request.get(
          this.$http,
          `${process.env.VUE_APP_API_URL}/category/${categoryIDSplit[2]}?site_id=${siteId}`,
        )
        if (resCategory3.status === 200) {
          if (resCategory3.data.status) {
            this.model.category_name += ` > ${resCategory3.data.data.name}`
          } else {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'Opps! Something wrong',
                icon: 'AlertOctagonIcon',
                variant: 'danger',
                text: String(this.showError(resCategory3.data.error, ',')),
              },
            })
          }
        }
      }
    },
    PaginationPerPage(data) {
      this.historyShow = data
    },
    onPageChange(page) {
      this.currentPageHistory = page
    },
    getDataResold(id) {
      // eslint-disable-next-line eqeqeq
      this.resoldList = this.items.find(item => item.id == id)
    },
  },
}
</script>

<style lang="sass" scoped>
.box
  align-items: center
.img-important
  width: 100px

  img
    width: 100%
.space-between
  justify-content: space-between
  align-items: center
  margin-bottom: 14px
  &:not(:last-child)
    border-bottom: 1px solid #ccc
</style>
